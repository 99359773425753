import React, { FC, useMemo } from "react"
import { PageWrapper, IPageWrapper } from "shared"

import { useLocalizationContext } from "@context"
import { getItemSidebarNavigation } from "@constants"

type ILocalizedPageWrapper = Omit<IPageWrapper, "l" | "itemSidebarNavigation">

export const LocalizedPageWrapper: FC<ILocalizedPageWrapper> = (props) => {
  const { l } = useLocalizationContext()

  const itemSidebarNavigation = useMemo(() => getItemSidebarNavigation(l), [l])

  return <PageWrapper {...{ ...props, l, itemSidebarNavigation }} />
}
