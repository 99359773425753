import React, { useRef, useCallback, useMemo } from "react"
import { Stack, styled, IconButton } from "@mui/material"
import ClearIcon from "@mui/icons-material/Clear"
import { Modal } from "shared"

import { useLocalizationContext } from "@context"
import { HeuristicAnalysisChart } from "./heuristic_analysis_chart"
import { FeaturesTable } from "./features_table"
import { parseFeatures } from "./_utils"
import { TOnDisplaySwitch } from "./_types"
import { IHeuristicVA } from "@types"

interface IHeuristicAnalysisModal {
  HVAData: IHeuristicVA
  setIsOpenModal: () => void
}

export const HeuristicAnalysisModal: React.FC<IHeuristicAnalysisModal> = ({
  HVAData: { features, underlay_data },
  setIsOpenModal,
}) => {
  const { l } = useLocalizationContext()

  const { chartData, tableData } = useMemo(() => parseFeatures(features), [])

  const displaySwitchRef = useRef<TOnDisplaySwitch | null>(null)

  const onDisplaySwitch = useCallback((id: string, shouldDisplay: boolean) => {
    displaySwitchRef.current && displaySwitchRef.current(id, shouldDisplay)
  }, [])

  return (
    <Modal
      title={l.charts.anomalyAnalysis}
      setIsOpenModal={setIsOpenModal}
      isOpenModal={true}
      hideFooter
      enableVerticalScroll
      size="large"
      headerChildren={
        <Stack direction="row" gap="7px" pr="10px">
          <SIconButton onClick={setIsOpenModal}>
            <ClearIcon />
          </SIconButton>
        </Stack>
      }
    >
      <Stack p="20px">
        <HeuristicAnalysisChart data={chartData} underlayData={underlay_data} displaySwitchRef={displaySwitchRef} />
        <FeaturesTable rows={tableData} onDisplaySwitch={onDisplaySwitch} l={l} />
      </Stack>
    </Modal>
  )
}

const SIconButton = styled(IconButton)({
  width: "35px",
  height: "35px",
})
