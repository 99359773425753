import React from "react"
import { Stack, Typography, styled, Switch } from "@mui/material"
import { GridColumns } from "@mui/x-data-grid"
import { ILocale } from "shared"

import { TOnDisplaySwitch } from "../_types"

export const getColumns = (onDisplaySwitch: TOnDisplaySwitch, l: ILocale): GridColumns => {
  const {
    ui: { description },
    recommendation: {
      hva: { frequency, defect, show },
    },
  } = l

  return [
    {
      field: "id",
      hide: true,
    },
    {
      field: "index",
      headerName: "№",
      width: 50,
      renderCell: ({ row: { id, color } }) => (
        <SRowIndex
          color={color}
          children={<Typography variant="button3" component="p" fontWeight={700} children={"0" + (id + 1)} />}
        />
      ),
    },
    {
      field: "name",
      width: 120,
      headerName: frequency,
      renderCell: ({ value }) => value || "—",
    },
    {
      field: "description",
      headerName: description,
      flex: 1,

      renderCell: ({ value }) => value || "—",
    },
    {
      field: "defect",
      headerName: defect,
      flex: 1,
      renderCell: ({ value }) => value || "—",
    },
    {
      field: "shouldDisplay",
      headerName: show,
      width: 120,
      renderCell: ({ row: { label } }) => (
        <Switch defaultChecked onChange={(event): void => onDisplaySwitch(label, event.target.checked)} />
      ),
    },
  ]
}

const SRowIndex = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "color",
})<{ color: string }>(({ theme, color }) => ({
  width: "40px",
  height: "24px",
  borderRadius: "6px",
  background: color,
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.secondary.main,
}))
