import React, { Suspense, useEffect, useMemo, memo, useCallback } from "react"
import { Route, Routes } from "react-router-dom"
import { CircularProgress } from "@mui/material"
import { PrivateRoute, ShortSystemNames } from "shared"

import { LoginPage, NotFoundPage } from "../../../pages"
import { LocalizedPageWrapper } from "../localized_page_wrapper"
import { useTypeActionsHook, useTypedSelectorHook } from "@utils"
import { privateRoutes, routePaths } from "@constants"

const LocalizedPageWrapperMemo = memo(LocalizedPageWrapper)

export const RoutesComponent: React.FC = () => {
  const { applicationStart, logout } = useTypeActionsHook()
  const {
    main: { isAuth, isApplicationStartReady },
    profile: { organization },
  } = useTypedSelectorHook((state) => state)

  const logoutMemo = useCallback(() => {
    logout()
  }, [])

  const routes = useMemo(
    () =>
      privateRoutes.map(({ path, element: Page }) => (
        <Route path={path} element={<Suspense fallback={<CircularProgress />} children={<Page />} />} key={path} />
      )),
    []
  )

  useEffect(() => {
    applicationStart()
  }, [])

  if (!isApplicationStartReady) return null

  return (
    <Routes>
      <Route
        element={
          <LocalizedPageWrapperMemo
            isAdmin={isAuth}
            isShowSystemSwitching={organization?.switch_dc_rp}
            currentSystem={ShortSystemNames.RP}
            logout={logoutMemo}
          />
        }
      >
        <Route path={routePaths.HOME} element={<LoginPage />} />

        <Route element={<PrivateRoute isAuth={isAuth} />}>{routes}</Route>
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}
