import { PaletteColor } from "@mui/material"
import { theme } from "shared"

import { IHeuristicVAFeature } from "@types"
import { IParsedFeatures } from "./_types"

export function parseFeatures(features: IHeuristicVAFeature[]): IParsedFeatures {
  const chartData: IParsedFeatures["chartData"] = {}
  const tableData: IParsedFeatures["tableData"] = []

  features.forEach((feature) => {
    const { id, data, label } = feature
    const color = getColorByIndex(id)

    chartData[label.label] = { data, color }
    tableData.push({ ...{ id, color, ...label } })
  })

  return {
    chartData,
    tableData,
  }
}

function getColorByIndex(id: number): string {
  const colorCount = Object.keys(theme.palette.charts).length
  const index = ((): number => {
    const index = id + 1

    return index > colorCount ? index - colorCount : index
  })()

  return theme.palette.charts[`A${index}00` as keyof PaletteColor]!
}
