import React, { useMemo } from "react"
import { Box, Stack, Typography, styled } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { ILocalizedFC } from "shared"

import { getColumns } from "./_columns"
import { IHeuristicVAFeature } from "@types"
import { TOnDisplaySwitch } from "../_types"

interface IFeaturesTable extends ILocalizedFC {
  rows: IHeuristicVAFeature["label"][]
  onDisplaySwitch: TOnDisplaySwitch
}

export const FeaturesTable: React.FC<IFeaturesTable> = ({ rows, onDisplaySwitch, l }) => {
  const columns = useMemo(() => getColumns(onDisplaySwitch, l), [l])

  return (
    <Box padding="20px">
      <Stack direction="row" alignItems="center">
        <Typography children={l.charts.sources} />
      </Stack>
      <Box>
        <SDataGrid
          columns={columns.map((c) => ({ ...c, sortable: false }))}
          rows={rows}
          rowCount={rows.length}
          rowHeight={32}
          disableSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          hideFooter
          disableExtendRowFullWidth
        />
      </Box>
    </Box>
  )
}

const SDataGrid = styled(DataGrid, {
  shouldForwardProp: (prop) => prop !== "rowCount",
})<{ rowCount: number }>(({ rowCount }) => ({
  "height": `${56 + 32 * rowCount}px`,
  "width": "100%",

  ".MuiDataGrid-columnHeaderTitle": {
    height: "40px",
  },

  "& .MuiDataGrid-columnHeader.MuiDataGrid-withBorder": {
    border: "none",
  },
}))
