import { recsAPI } from "@api"
import { IHeuristicVA } from "@types"
import { useCallback, useEffect, useState } from "react"
import { ERequestStatus, useLatest, useRestAPIReducer } from "shared"

interface IUseHvaModalReturn {
  handleDetailsClick: (id: number) => void
  HVAData: IHeuristicVA
  reqStatusObj: Record<number, ERequestStatus>
  isModalOpen: boolean
  toggleModal: () => void
}

// TODO: сделать общий контроллер модалок - открыть/закрыть, дисейбл, продумать, как прокидывать пропсы в модалки
export const useHVAModal = (): IUseHvaModalReturn => {
  const [recId, setRecId] = useState<number | null>(null)

  const [reqStatusObj, setReqStatusObj] = useState<Record<number, ERequestStatus>>({})

  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = useCallback(() => setIsModalOpen((prev) => !prev), [])

  const recIdRef = useLatest(recId)

  const handleDetailsClick = useCallback((id: number) => {
    if (id === recIdRef.current) {
      toggleModal()
      return
    }

    setRecId(id)
  }, [])

  const [{ data: HVAData, status }, actions] = useRestAPIReducer<IHeuristicVA>({ features: [], underlay_data: [] })

  useEffect(() => {
    if (!recId) return

    recsAPI.getHeuristicVAData(
      {
        params: { id: recId },

        onSuccess: ({ features, underlay_data }) => {
          const isEmpty = !features.length && !underlay_data.length

          setReqStatusObj((prev) => ({ ...prev, [recId]: isEmpty ? ERequestStatus.EMPTY : ERequestStatus.READY }))
          toggleModal()
        },

        onPending: (isPending) => {
          if (isPending) setReqStatusObj((prev) => ({ ...prev, [recId]: ERequestStatus.PENDING }))
        },

        onError: () => {
          setReqStatusObj((prev) => ({ ...prev, [recId]: ERequestStatus.ERROR }))
        },
      },
      actions
    )
  }, [recId])

  return {
    handleDetailsClick,
    HVAData,
    reqStatusObj,
    isModalOpen,
    toggleModal,
  }
}
