import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import { ILocale, theme } from "shared"

import { HeuristicAnalysisChartStyle } from "./_AnalyzerChartStyle"
import { IHeuristicChartData } from "../_types"
import { IHeuristicVAData } from "@types"

type IHeuristicSeries = Record<string, am5xy.ColumnSeries>

export class HeuristicAnalysisChartClass {
  private chartId = "ha-chart"

  private root: am5.Root

  private chart: am5xy.XYChart
  private xAxis: am5xy.ValueAxis<am5xy.AxisRenderer>
  private yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>

  private series: IHeuristicSeries = {}

  private chartStyle: HeuristicAnalysisChartStyle

  constructor(data: IHeuristicChartData, underlayData: IHeuristicVAData[], private l: ILocale) {
    this.root = am5.Root.new(this.chartId)

    this.chart = this.createChart()
    this.xAxis = this.createXAxis()
    this.yAxis = this.createYAxis()

    this.chartStyle = new HeuristicAnalysisChartStyle(this.root, this.chart, this.yAxis, this.xAxis)

    if (underlayData.length) this.createUnderlaySeries(underlayData)
    this.createSeries(data)

    this.setCursor()
    this.setCtrlScroll()
  }

  private createChart(): am5xy.XYChart {
    const chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        isMeasured: true,
        panX: true,
        paddingRight: 30,
      })
    )

    chart.appear(500, 50)

    return chart
  }

  private createXAxis(): am5xy.ValueAxis<am5xy.AxisRenderer> {
    const {
      frequency,
      units: { hz },
    } = this.l.recommendation.hva

    const xAxis = this.chart.xAxes.push(
      am5xy.ValueAxis.new(this.root, {
        strictMinMaxSelection: true,
        extraMin: 0.01,
        extraMax: 0.007,
        maxDeviation: 0,
        renderer: am5xy.AxisRendererX.new(this.root, {
          minGridDistance: 100,
        }),
      })
    )

    xAxis.children.push(
      am5.Label.new(this.root, {
        text: `${frequency}, ${hz}`,
        textAlign: "center",
        x: am5.p50,
        width: 20,
        fill: am5.color(theme.palette.secondary.A400 as string),
        fontSize: 16,
      })
    )

    return xAxis
  }

  private createYAxis(): am5xy.ValueAxis<am5xy.AxisRenderer> {
    const {
      amplitude,
      units: { mms2 },
    } = this.l.recommendation.hva

    const yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        strictMinMaxSelection: true,
        extraMin: 0.02,
        extraMax: 0.007,
        renderer: am5xy.AxisRendererY.new(this.root, {}),
      })
    )

    yAxis.children.unshift(
      am5.Label.new(this.root, {
        text: `${amplitude}, ${mms2}`,
        textAlign: "center",
        y: am5.p50,
        rotation: -90,
        fill: am5.color(theme.palette.secondary.A400 as string),
        fontSize: 16,
      })
    )

    return yAxis
  }

  private setCursor(): void {
    const cursor = this.chart.set(
      "cursor",
      am5xy.XYCursor.new(this.root, {
        behavior: "none",
      })
    )
    cursor.lineY.set("visible", false)
  }

  private setCtrlScroll(): void {
    this.chart.plotContainer.events.on("wheel", (ev) => {
      if (ev.originalEvent.ctrlKey) {
        ev.originalEvent.preventDefault()
        this.chart.set("wheelX", "none")
        this.chart.set("wheelY", "none")
      } else {
        this.chart.set("wheelX", "none")
        this.chart.set("wheelY", "zoomX")
      }
    })
  }

  createUnderlaySeries(underlayData: IHeuristicVAData[]): void {
    const series = this.chart.series.push(
      am5xy.SmoothedXLineSeries.new(this.root, {
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueYField: "amplitudes",
        valueXField: "frequency",
        maskBullets: false,
        maskContent: false,
        fill: am5.color(theme.palette.secondary.A400!),
        stroke: am5.color(theme.palette.secondary.A400!),
      })
    )

    series.data.setAll(underlayData)
  }

  private createSeries(chartData: IHeuristicChartData): void {
    const {
      frequency,
      amplitude,
      units: { hz, mms2 },
    } = this.l.recommendation.hva

    const keys = Object.keys(chartData)

    keys.forEach((key) => {
      const { data, color } = chartData[key]

      const series = this.chart.series.push(
        am5xy.ColumnSeries.new(this.root, {
          xAxis: this.xAxis,
          yAxis: this.yAxis,
          valueYField: "amplitudes",
          valueXField: "frequency",
          maskBullets: false,
          maskContent: false,
          fill: am5.color(color),
          stroke: am5.color(color),
        })
      )

      series.columns.template.setAll({
        strokeWidth: 5,
        // strokeDasharray: [5, 5],
      })

      series.data.setAll(data)

      series.columns.template.setAll({
        tooltipText: `[bold]${frequency}:[/] {valueX} ${hz} \n[bold]${amplitude}:[/] {valueY} ${mms2}`,
        tooltipY: 0,
      })

      this.series[key] = series
    })
  }

  toggleDisplay = (key: string, shouldDisplay: boolean): void => {
    const targetSeries = this.series[key]

    if (!targetSeries) return

    targetSeries.set("forceHidden", !shouldDisplay)
  }

  dispose(): void {
    this.root.dispose()
  }
}
