import React, { useEffect, useRef } from "react"
import { Box, styled } from "@mui/material"

import { HeuristicAnalysisChartClass } from "./_AnalyzerChartClass"
import { IHeuristicChartData, TOnDisplaySwitch } from "../_types"
import { IHeuristicVAData } from "@types"
import { useLocalizationContext } from "@context"

interface IHeuristicAnalysisChart {
  data: IHeuristicChartData
  underlayData: IHeuristicVAData[]
  displaySwitchRef: React.MutableRefObject<TOnDisplaySwitch | null>
}

export const HeuristicAnalysisChart: React.FC<IHeuristicAnalysisChart> = ({ data, underlayData, displaySwitchRef }) => {
  const { l } = useLocalizationContext()

  const chartRef = useRef<HeuristicAnalysisChartClass | null>(null)

  useEffect(() => {
    if (Object.keys(data).length && chartRef.current === null) {
      chartRef.current = new HeuristicAnalysisChartClass(data, underlayData, l)

      if (displaySwitchRef.current === null) displaySwitchRef.current = chartRef.current.toggleDisplay
    }

    return () => {
      if (!chartRef.current) return
      chartRef.current.dispose()
    }
  }, [data])

  return (
    <Box position="relative">
      <SChartRootDiv id="ha-chart" />
      <Box
        sx={{
          position: "absolute",
          width: "60px",
          height: "20px",
          bottom: "0",
          left: "0",
          backgroundColor: "#080808",
        }}
      />
    </Box>
  )
}

const SChartRootDiv = styled("div")({
  width: "100%",
  height: "350px",
  userSelect: "none",
})
