import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive"
import { theme } from "shared"

export class HeuristicAnalysisChartStyle {
  constructor(
    private root: am5.Root,

    private chart: am5xy.XYChart,
    private yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
    private xAxis: am5xy.ValueAxis<am5xy.AxisRenderer>
  ) {
    this.setStyles()
  }

  private setStyles(): void {
    this.setRootThemes()
    this.setYAxisStyles()
    this.setXAxisStyles()

    this.addZoomOutButtonStyle()
  }

  private setRootThemes(): void {
    this.root.setThemes([am5themes_Animated.new(this.root), am5themes_Responsive.new(this.root)])
  }

  private setYAxisStyles(): void {
    const yRenderer = this.yAxis.get("renderer")

    yRenderer.labels.template.setAll({
      fill: am5.color(theme.palette.secondary.A400 as string),
    })

    yRenderer.grid.template.setAll({
      stroke: am5.color(theme.palette.secondary.A400 as string),
      strokeWidth: 0.5,
    })
  }

  private setXAxisStyles(): void {
    const xRenderer = this.xAxis.get("renderer")

    xRenderer.grid.template.setAll({
      stroke: am5.color(theme.palette.secondary.A400 as string),
      strokeWidth: 0.5,
    })

    xRenderer.labels.template.setAll({
      fill: am5.color(theme.palette.secondary.A400 as string),
    })
  }

  private addZoomOutButtonStyle(): void {
    this.chart.zoomOutButton.set("forceHidden", true)
  }
}
