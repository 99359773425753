import React, { FC } from "react"
import { Helmet } from "react-helmet"

import { useLocalizationContext } from "@context"

/**
 * Компонент с метой страницы
 *
 * @returns {FC} - FC
 */
const Head: FC = () => {
  const {
    l: {
      meta: { rp },
    },
  } = useLocalizationContext()

  return (
    <Helmet>
      <meta name="title" content={rp.title} />

      {/* Google meta */}
      <meta property="og:title" content={rp.ogTitle} />
      <meta property="og:description" content={rp.ogDescription} />

      {/* Twitter meta */}
      <meta name="twitter:title" content={rp.twitterTitle} />
      <meta name="twitter:description" content={rp.twitterDescription} />

      <title>{rp.titleContent}</title>
    </Helmet>
  )
}

export { Head }
